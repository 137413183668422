
import { all } from 'redux-saga/effects';
import adminSaga from './adminSaga';
import proposalSaga from './proposalSaga';
import crudSaga from './crudSaga';
import authSaga from './authSaga';

export default function* rootSaga() {
    yield all([
        adminSaga(),
        proposalSaga(),
        crudSaga(),
        authSaga(),
    ]);
}
                