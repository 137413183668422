import { put, takeEvery } from "redux-saga/effects";
// import { call, put, takeEvery, select } from "redux-saga/effects";
import axios from "axios";
import * as types from "../actions/proposalTypes";
import * as actions from "../actions/proposalActions";
import config from "../../config";
import { callApiWithToken } from "./apiHelperSaga";

// const API_URL = 'https://jsonplaceholder.typicode.com/posts';
// const API_URL = 'https://tsapi.schedulemycrew.ca/api/proposalapi/';
// const API_URL = "http://localhost:60200/api/proposalapi/";

function* fetchProposal() {
  // const token = yield select((state) => state.admin.data.accessToken);

  const apiCall = (token) => {
    return axios.post(
      config.APIURL.proposalapi.display,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  try {
    const response = yield callApiWithToken(apiCall);
    yield put(actions.fetchProposalSuccess(response));
  } catch (error) {
    yield put(actions.fetchProposalFailure(error.message));
  }
}

// function* fetchProposal() {
//   console.log("fetchProposal");

//   const token = yield select((state) => state.admin.data.accessToken);

//   try {
//     const response = yield call(
//       axios.post,
//       config.APIURL.proposalapi.display,
//       {},
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     yield put(actions.fetchProposalSuccess(response));
//   } catch (error) {
//     yield put(actions.fetchProposalFailure(error.message));
//   }
// }

function* fetchProposalById(action) {
  // const token = yield select((state) => state.admin.data.accessToken);

  const apiCall = (token) => {
    return axios.post(
      config.APIURL.proposalapi.displaybyid,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  try {
    const response = yield callApiWithToken(apiCall);
    yield put(actions.fetchProposalByIdSuccess(response));
  } catch (error) {
    yield put(actions.fetchProposalByIdFailure(error.message));
  }
}

function* addProposal(action) {
  // const token = yield select((state) => state.admin.data.accessToken);

  const apiCall = (token) => {
    return axios.post(
      config.APIURL.proposalapi.create,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  try {
    // const response = yield call(
    //   axios.post,
    //   config.APIURL.proposalapi.create,
    //   action.payload,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    const response = yield callApiWithToken(apiCall);
    const recordId = response.data.id;
    yield put(actions.addProposalSuccess(response.data));

    if (typeof action.navigate === "function") {
      action.navigate(`/displayproposal/${recordId}`);
    } else {
      console.error("navigate function is not defined");
    }
  } catch (error) {
    yield put(actions.addProposalFailure(error.message));
  }
}

// function* updateProposal(action) {
//   try {
//     const response = yield call(
//       axios.put,
//       `${API_URL}/${action.payload.id}`,
//       action.payload
//     );
//     yield put(actions.updateProposalSuccess(response.data));
//   } catch (error) {
//     yield put(actions.updateProposalFailure(error.message));
//   }
// }

// function* deleteProposal(action) {
//   try {
//     yield call(axios.delete, `${API_URL}/${action.payload}`);
//     yield put(actions.deleteProposalSuccess(action.payload));
//   } catch (error) {
//     yield put(actions.deleteProposalFailure(error.message));
//   }
// }

function* proposalSaga() {
  yield takeEvery(types.FETCH_PROPOSAL_REQUEST, fetchProposal);
  yield takeEvery(types.FETCH_PROPOSAL_BYID_REQUEST, fetchProposalById);
  yield takeEvery(types.ADD_PROPOSAL_REQUEST, addProposal);
  // yield takeEvery(types.UPDATE_PROPOSAL_REQUEST, updateProposal);
  // yield takeEvery(types.DELETE_PROPOSAL_REQUEST, deleteProposal);
}

export default proposalSaga;
