
import * as types from './crudTypes';

export const fetchItemsRequest = () => ({
    type: types.FETCH_ITEMS_REQUEST,
});

export const fetchItemsSuccess = (items) => ({
    type: types.FETCH_ITEMS_SUCCESS,
    payload: items,
});

export const fetchItemsFailure = (error) => ({
    type: types.FETCH_ITEMS_FAILURE,
    payload: error,
});

export const addItemRequest = (item) => ({
    type: types.ADD_ITEM_REQUEST,
    payload: item,
});

export const addItemSuccess = (item) => ({
    type: types.ADD_ITEM_SUCCESS,
    payload: item,
});

export const addItemFailure = (error) => ({
    type: types.ADD_ITEM_FAILURE,
    payload: error,
});

export const updateItemRequest = (item) => ({
    type: types.UPDATE_ITEM_REQUEST,
    payload: item,
});

export const updateItemSuccess = (item) => ({
    type: types.UPDATE_ITEM_SUCCESS,
    payload: item,
});

export const updateItemFailure = (error) => ({
    type: types.UPDATE_ITEM_FAILURE,
    payload: error,
});

export const deleteItemRequest = (id) => ({
    type: types.DELETE_ITEM_REQUEST,
    payload: id,
});

export const deleteItemSuccess = (id) => ({
    type: types.DELETE_ITEM_SUCCESS,
    payload: id,
});

export const deleteItemFailure = (error) => ({
    type: types.DELETE_ITEM_FAILURE,
    payload: error,
});
                