import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginRequest } from "../redux/actions/adminActions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { data } = useSelector((state) => state.admin);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);


  useEffect(() => {
    if (data && data.errorlogin === "0") {
      // console.log("AuthContext");
      
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("access_token", data.accessToken);
      localStorage.setItem("refresh_token", data.refreshToken);
      localStorage.setItem("empid", data.empid);
      localStorage.setItem("empno", data.empno);
      localStorage.setItem("empname", data.empname);
      localStorage.setItem("email", data.email);
      localStorage.setItem("roleid", data.roleid);
      localStorage.setItem("companyid", data.companyid);

      setIsAuthenticated(true);
    } 
    // else {
    //   console.error("Invalid Employee No. or Password");
    // }
  }, [data]);

  const login = (username, password) => {
    dispatch(adminLoginRequest({ empno: username, password: password }));
  };

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
