import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProposalPlan from "./container/ProposalPlan";
import { PaymentProvider } from "./context/PaymentContext";
import ProposalList from "./container/ProposalList";
import AddItem from "./components/AddItem";
import LoginPage from "./container/LoginPage";
import ProtectedRoute from "./utils/ProtectedRoute";
// import List1 from "./components/List1";
import DisplayProposal from "./container/DisplayProposal";

function App() {
  return (
    <AuthProvider>
      <PaymentProvider>
        <Router>
          <div className="container mx-auto">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/proposal"
                element={
                  <ProtectedRoute>
                    <ProposalPlan />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/displayproposal/:id"
                element={
                  <ProtectedRoute>
                    <DisplayProposal />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/proposallist"
                element={
                  <ProtectedRoute>
                    <ProposalList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/additem"
                element={
                  <ProtectedRoute>
                    <AddItem />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </div>
        </Router>
      </PaymentProvider>
    </AuthProvider>
  );
}

export default App;
