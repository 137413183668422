import * as types from "../actions/proposalTypes";

const initialState = {
  recordId: null,
  data: [],
  databyid: [],
  sortConfig: { key: null, direction: null }, 
  loading: false,
  error: null,
};

const proposalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PROPOSAL_REQUEST:
    case types.FETCH_PROPOSAL_BYID_REQUEST:
    case types.ADD_PROPOSAL_REQUEST:
    case types.UPDATE_PROPOSAL_REQUEST:
    case types.DELETE_PROPOSAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        // data: action.payload.data.proposal,
      };
    case types.FETCH_PROPOSAL_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        databyid: action.payload.data,
        // databyid: action.payload.data.proposalbyid,
      };
      case types.CLEAR_PROPOSAL_DATA:
      return {
        ...state,
        databyid: [], // Clear the data when fetching new proposal
      };
    case types.ADD_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        recordId: action.payload.id,
      };
    case types.UPDATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case types.DELETE_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case types.SET_SORT_CONFIG:
      return {
        ...state,
        sortConfig: action.payload,
      };

    case types.FETCH_PROPOSAL_FAILURE:
    case types.FETCH_PROPOSAL_BYID_FAILURE:
    case types.ADD_PROPOSAL_FAILURE:
    case types.UPDATE_PROPOSAL_FAILURE:
    case types.DELETE_PROPOSAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default proposalReducer;
