
import * as types from '../actions/crudTypes';

const initialState = {
    items: [],
    loading: false,
    error: null,
};

const crudReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case types.FETCH_ITEMS_REQUEST:
        case types.ADD_ITEM_REQUEST:
        case types.UPDATE_ITEM_REQUEST:
        case types.DELETE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.data.proposal,
            };
        case types.ADD_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.payload],
            };
        case types.UPDATE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                items: state.items.map(item =>
                    item.id === action.payload.id ? action.payload : item
                ),
            };
        case types.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                items: state.items.filter(item => item.id !== action.payload),
            };
        case types.FETCH_ITEMS_FAILURE:
        case types.ADD_ITEM_FAILURE:
        case types.UPDATE_ITEM_FAILURE:
        case types.DELETE_ITEM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default crudReducer;
                