
import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import * as types from '../actions/crudTypes';
import * as actions from '../actions/crudActions';

const API_URL = 'https://tsapi.schedulemycrew.ca/api/proposalapi/';
// const API_URL = 'http://localhost:5080/api/proposal/';

function* fetchItems() {
    const token = yield select((state) => state.admin.data.accessToken);
    try {
        const response = yield call(axios.post, API_URL + "display", {}, {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in Authorization header
            },
          });
        yield put(actions.fetchItemsSuccess(response));
    } catch (error) {
        yield put(actions.fetchItemsFailure(error.message));
    }
}

function* addItem(action) {
    const token = yield select((state) => state.admin.data.accessToken);
    try {
        const response = yield call(axios.post, API_URL + "create", action.payload, {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in Authorization header
            },
          });
        yield put(actions.addItemSuccess(response.data));
    } catch (error) {
        yield put(actions.addItemFailure(error.message));
    }
}

function* updateItem(action) {
    try {
        const response = yield call(
            axios.put,
            `${API_URL}/${action.payload.id}`,
            action.payload
        );
        yield put(actions.updateItemSuccess(response.data));
    } catch (error) {
        yield put(actions.updateItemFailure(error.message));
    }
}

function* deleteItem(action) {
    try {
        yield call(axios.delete, `${API_URL}/${action.payload}`);
        yield put(actions.deleteItemSuccess(action.payload));
    } catch (error) {
        yield put(actions.deleteItemFailure(error.message));
    }
}

function* crudSaga() {
    yield takeEvery(types.FETCH_ITEMS_REQUEST, fetchItems);
    yield takeEvery(types.ADD_ITEM_REQUEST, addItem);
    yield takeEvery(types.UPDATE_ITEM_REQUEST, updateItem);
    yield takeEvery(types.DELETE_ITEM_REQUEST, deleteItem);
}

export default crudSaga;
                