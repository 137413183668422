
import { combineReducers } from 'redux';
import crudReducer from './crudReducer';
import adminReducer from './adminReducer';
import proposalReducer from './proposalReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
    crud: crudReducer,
    admin: adminReducer,
    proposal: proposalReducer,
    auth: authReducer,
});

export default rootReducer;
                