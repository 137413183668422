import React, { useRef } from 'react';
import { usePaymentContext } from "../context/PaymentContext";
import Cards from "./Cards";
import ExtraInfoCard from "./ExtraInfoCard";
import FinalCard from "./FinalCard";
import ClientCard from "./ClientCard";
// import ExportData from "./ExportData";
// import ExportData1 from "./ExportData1";
import ExportData2 from "./ExportData2";
// import ExportData3 from "./ExportData3";
// import ExportData4 from "./ExportData4";
// import ExportData5 from "./ExportData5";
// import FinalCard2 from "./FinalCard2";
const ProposalCards = () => {
  const { paymentData } = usePaymentContext();
  const {year2Percent, year3Percent, year4Percent, year5Percent} = paymentData;

  // Define refs for both tables
  const dataRef1 = useRef(null);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  // Determine the number of grid columns based on the conditions
let gridColumnsClass = 'grid-cols-1 md:grid-cols-4'; // Default to 4 columns

if (year5Percent) {
  gridColumnsClass = 'grid-cols-1 md:grid-cols-6'; // If year5Percent exists, use 6 columns
} else if (year4Percent) {
  gridColumnsClass = 'grid-cols-1 md:grid-cols-5'; // If only year4Percent exists, use 5 columns
}
  return (
    <>
      <div className="container mx-auto bg-gray-100 p-2">
        {/* <div className="grid grid-cols-1 md:grid-cols-6 gap-6"> */}
        <div className={`grid ${gridColumnsClass} gap-2`}>
          <Cards year="1" />
          {year2Percent && <Cards year="2" />}
          {year3Percent && <Cards year="3" />}
          {year4Percent && <Cards year="4" />}
          {year5Percent && <Cards year="5" />}
          <ExtraInfoCard />
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FinalCard dataRef={dataRef1}/>
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>
        <div className="grid grid-cols-1 gap-6">
          <ClientCard chartRef1={chartRef1} chartRef2={chartRef2}/>
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>
        <div className="grid grid-cols-1 gap-6">
          {/* <ExportData dataRef1={dataRef1}  chartRef1={chartRef1} chartRef2={chartRef2}/> */}
          {/* <ExportData1 dataRef1={dataRef1}  chartRef1={chartRef1} chartRef2={chartRef2}/> */}
          <ExportData2 dataRef1={dataRef1}  chartRef1={chartRef1} chartRef2={chartRef2}/>
          {/* <ExportData3 dataRef1={dataRef1}  chartRef1={chartRef1} chartRef2={chartRef2}/>
          <ExportData4 paymentData={paymentData} chartRef1={chartRef1} chartRef2={chartRef2}/>
          <ExportData5 paymentData={paymentData} chartRef1={chartRef1} chartRef2={chartRef2}/> */}
        </div>
      </div>
    </>
  );
};
export default ProposalCards;
