
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addItemRequest } from '../redux/actions/crudActions';

const AddItem = () => {
    const [corpno, setCorpno] = useState('');
    const [sitename, setSitename] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = async(e) => {
        e.preventDefault();
        // dispatch(addItemRequest({ corpno, sitename }));
        const response = await dispatch(addItemRequest({ corpno, sitename }));
        console.log("response");
        console.log(response);
        
        setCorpno('');
        setSitename('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={corpno}
                onChange={(e) => setCorpno(e.target.value)}
                placeholder="Enter corpno"
            />
            <input
                type="text"
                value={sitename}
                onChange={(e) => setSitename(e.target.value)}
                placeholder="Enter Sitename"
            />
            <button type="submit">Add Proposal</button>
        </form>
    );
};

export default AddItem;
                