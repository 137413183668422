
import * as types from './proposalTypes';

export const fetchProposalRequest = () => ({
    type: types.FETCH_PROPOSAL_REQUEST,
});

export const fetchProposalSuccess = (data) => ({
    type: types.FETCH_PROPOSAL_SUCCESS,
    payload: data,
});

export const fetchProposalFailure = (error) => ({
    type: types.FETCH_PROPOSAL_FAILURE,
    payload: error,
});

export const fetchProposalByIdRequest = (data) => ({
    type: types.FETCH_PROPOSAL_BYID_REQUEST,
    payload: data,
});

export const fetchProposalByIdSuccess = (data) => ({
    type: types.FETCH_PROPOSAL_BYID_SUCCESS,
    payload: data,
});

export const fetchProposalByIdFailure = (error) => ({
    type: types.FETCH_PROPOSAL_BYID_FAILURE,
    payload: error,
});

export const addProposalRequest = (data, navigate) => ({
    type: types.ADD_PROPOSAL_REQUEST,
    payload: data,
    navigate,
});

export const addProposalSuccess = (data) => ({
    type: types.ADD_PROPOSAL_SUCCESS,
    payload: data,
});

export const addProposalFailure = (error) => ({
    type: types.ADD_PROPOSAL_FAILURE,
    payload: error,
});

export const updateProposalRequest = (data) => ({
    type: types.UPDATE_PROPOSAL_REQUEST,
    payload: data,
});

export const updateProposalSuccess = (data) => ({
    type: types.UPDATE_PROPOSAL_SUCCESS,
    payload: data,
});

export const updateProposalFailure = (error) => ({
    type: types.UPDATE_PROPOSAL_FAILURE,
    payload: error,
});

export const deleteProposalRequest = (id) => ({
    type: types.DELETE_PROPOSAL_REQUEST,
    payload: id,
});

export const deleteProposalSuccess = (id) => ({
    type: types.DELETE_PROPOSAL_SUCCESS,
    payload: id,
});

export const deleteProposalFailure = (error) => ({
    type: types.DELETE_PROPOSAL_FAILURE,
    payload: error,
});

export const setSortConfig = (config) => ({
    type: types.SET_SORT_CONFIG,
    payload: config,
  });
  
                