import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { formatCurrency } from "../utility/utility";
import { getDateTime } from "../utility/utility";
import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  TextRun,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  PageNumber,
  TextWrappingType,
  TextWrappingSide,
  TabStopType,
  TabStopPosition,
  VerticalAlign,
  PageOrientation,
  BorderStyle,
} from "docx";
import { saveAs } from "file-saver";
import backgroundImage from "../static/img/imagebg.png";
import overlayImage from "../static/img/pillar.png";
import waterMarkImage from "../static/img/pillar-watermark.jpg";
import pillarImage1 from "../static/img/pillar-1.jpg";
import pillarImage2 from "../static/img/pillar-2.jpg";
import pillarImage3 from "../static/img/pillar-3.jpg";
import pillarImage4 from "../static/img/pillar-4.png";

const ExportData2 = () => {
  const { paymentData, contextData } = usePaymentContext();

  const {
    // s_TotalHoursYear,
    s_Payrate1,
    s_HoursYear,
    s_LeapHoursYear,
    s_BillingRate1,
    s_StatHoursYear,
    s_StatBillingRate1,
    c_Payrate1,
    c_HoursYear,
    c_LeapHoursYear,
    c_BillingRate1,
    c_StatHoursYear,
    c_StatBillingRate1,
    p_Payrate1,
    p_HoursYear,
    p_LeapHoursYear,
    p_BillingRate1,
    p_StatHoursYear,
    p_StatBillingRate1,
    e1_Payrate1,
    e1_HoursYear,
    e1_LeapHoursYear,
    e1_BillingRate1,
    e1_StatHoursYear,
    e1_StatBillingRate1,
    e2_Payrate1,
    e2_HoursYear,
    e2_LeapHoursYear,
    e2_BillingRate1,
    e2_StatHoursYear,
    e2_StatBillingRate1,
  } = contextData;

  const {
    corpNo,
    siteName,
    address,
    city,
    province,
    postalCode,
    date_Created,

    supervisorHours,
    conciergeHours,
    patrolHours,
    extraHours1,
    extraHours2 ,
    supervisorTitle,
    conciergeTitle,
    patrolTitle,
    extraTitle1,
    extraTitle2,
    patrolProfit,
    extraProfit1,
    extraProfit2,
    hst,
    unionAmt,
    vacationAmt,
  } = paymentData;

  const totalhours_reg_week = supervisorHours +  conciergeHours +  patrolHours + extraHours1 + extraHours2;

  const s_RegLeapHours = s_HoursYear + s_LeapHoursYear || 0;
  const s_RegLeapAmount = s_BillingRate1 * s_RegLeapHours || 0;
  const s_StatAmount = s_StatBillingRate1 * s_StatHoursYear || 0;

  const c_RegLeapHours = c_HoursYear + c_LeapHoursYear || 0;
  const c_RegLeapAmount = c_BillingRate1 * c_RegLeapHours || 0;
  const c_StatAmount = c_StatBillingRate1 * c_StatHoursYear || 0;

  const p_RegLeapHours = p_HoursYear + p_LeapHoursYear || 0;
  const p_RegLeapAmount = p_BillingRate1 * p_RegLeapHours || 0;
  const p_StatAmount = p_StatBillingRate1 * p_StatHoursYear || 0;

  const e1_RegLeapHours = e1_HoursYear + e1_LeapHoursYear || 0;
  const e1_RegLeapAmount = e1_BillingRate1 * e1_RegLeapHours || 0;
  const e1_StatAmount = e1_StatBillingRate1 * e1_StatHoursYear || 0;

  const e2_RegLeapHours = e2_HoursYear + e2_LeapHoursYear || 0;
  const e2_RegLeapAmount = e2_BillingRate1 * e2_RegLeapHours || 0;
  const e2_StatAmount = e2_StatBillingRate1 * e2_StatHoursYear || 0;

  var preTaxAmount;
  if (!patrolProfit)
    preTaxAmount =
      s_RegLeapAmount +
      s_StatAmount +
      c_RegLeapAmount +
      c_StatAmount +
      unionAmt +
      vacationAmt;
  else if (patrolProfit)
    preTaxAmount =
      s_RegLeapAmount +
      s_StatAmount +
      c_RegLeapAmount +
      c_StatAmount +
      p_RegLeapAmount +
      p_StatAmount +
      unionAmt +
      vacationAmt;

  const hstAmount = (preTaxAmount * hst) / 100;
  const grandTotal = Number(preTaxAmount) + Number(hstAmount);
  const monthlyTotal = grandTotal / 12;

  const watermark = async () => {
    const waterMarkResponse = await fetch(waterMarkImage);
    const waterMarkBlob = await waterMarkResponse.blob();
    const waterMarkBuffer = await waterMarkBlob.arrayBuffer();

    return new Paragraph({
      children: [
        new ImageRun({
          data: waterMarkBuffer, // Image data for watermark
          transformation: {
            width: 100, // Adjust watermark width
            height: 53, // Adjust watermark height
          },
          floating: {
            horizontalPosition: {
              relative: "page",
              align: "center", // Center horizontally
            },
            verticalPosition: {
              relative: "page",
              align: "center", // Center vertically
            },
            wrap: {
              type: TextWrappingType.NONE, // Ensure it's behind the text
            },
            behindDocument: true,
            margins: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        }),
      ],
    });
  };

  const createCell = (text, options = {}) => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: text,
              bold: options.bold || false,
              font: "Helvetica",
              size: 20,
            }),
          ],
          alignment: options.alignment || undefined,
        }),
      ],
      margins: {
        top: 150,
        bottom: 150,
        left: 150,
        right: 150,
      },
      columnSpan: options.colspan || 1,
      rowSpan: options.rowspan || 1,
      borders: {
        top: { style: BorderStyle.SINGLE },
        bottom: { style: BorderStyle.SINGLE },
        left: { style: BorderStyle.SINGLE },
        right: { style: BorderStyle.SINGLE },
      },
    });
  };

  const dataTable = async () => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            createCell(supervisorTitle + " @ $" + s_Payrate1, { bold: true }),
            createCell("Yearly Hours", { bold: true }),
            createCell("Billing Rate", { bold: true }),
            createCell("Total", { bold: true }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Regular + Leap Hours"), // Rowspan
            createCell(String(s_RegLeapHours)),
            createCell(String(formatCurrency(s_BillingRate1))),
            createCell(String(formatCurrency(s_RegLeapAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Statutory Holiday Hours"), // Rowspan
            createCell(String(s_StatHoursYear)),
            createCell(String(formatCurrency(s_StatBillingRate1))),
            createCell(String(formatCurrency(s_StatAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell(conciergeTitle + " @ $" + c_Payrate1, { bold: true }),
            createCell("Yearly Hours", { bold: true }),
            createCell("Billing Rate", { bold: true }),
            createCell("Total", { bold: true }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Regular + Leap Hours"), // Rowspan
            createCell(String(c_RegLeapHours)),
            createCell(String(formatCurrency(c_BillingRate1))),
            createCell(String(formatCurrency(c_RegLeapAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Statutory Holiday Hours"), // Rowspan
            createCell(String(c_StatHoursYear)),
            createCell(String(formatCurrency(c_StatBillingRate1))),
            createCell(String(formatCurrency(c_StatAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        ...(patrolProfit
          ? [
              new TableRow({
                children: [
                  createCell(`${patrolTitle} @ ${formatCurrency(p_Payrate1)}`, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(p_RegLeapHours)),
                  createCell(String(formatCurrency(p_BillingRate1))),
                  createCell(String(formatCurrency(p_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(p_StatHoursYear)),
                  createCell(String(formatCurrency(p_StatBillingRate1))),
                  createCell(String(formatCurrency(p_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        ...(extraProfit1
          ? [
              new TableRow({
                children: [
                  createCell(extraTitle1 + " @ $" + e1_Payrate1, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(e1_RegLeapHours)),
                  createCell(String(formatCurrency(e1_BillingRate1))),
                  createCell(String(formatCurrency(e1_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(e1_StatHoursYear)),
                  createCell(String(formatCurrency(e1_StatBillingRate1))),
                  createCell(String(formatCurrency(e1_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        ...(extraProfit2
          ? [
              new TableRow({
                children: [
                  createCell(extraTitle2 + " @ $" + e2_Payrate1, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(e2_RegLeapHours)),
                  createCell(String(formatCurrency(e2_BillingRate1))),
                  createCell(String(formatCurrency(e2_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(e2_StatHoursYear)),
                  createCell(String(formatCurrency(e2_StatBillingRate1))),
                  createCell(String(formatCurrency(e2_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Total pre-Tax", { bold: true }),
            createCell(String(formatCurrency(preTaxAmount)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("HST @13%", { bold: true }),
            createCell(String(formatCurrency(hstAmount)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Grand Total", { bold: true }),
            createCell(String(formatCurrency(grandTotal)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Monthly Invoice", { bold: true }),
            createCell(String(formatCurrency(monthlyTotal)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  };

  const dataExtraTable = async () => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            createCell("Hourly Billing Rate"),
            createCell("Statutory Holiday Rate"),
            createCell("Hourly Overtime Rate"),
            createCell("Less than 24hrs Notice (First 4 Hours)"),
            createCell("Less than 4hrs Notice (First 4 Hours)"),
          ],
        }),


        new TableRow({
          children: [
            createCell("$26.50", { bold: true }),
            createCell("$66.25", { bold: true }),
            createCell("$39.75", { bold: true }),
            createCell("$39.75", { bold: true }),
            createCell("$66.25", { bold: true }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  };

  const generateDocument = async () => {
    const watermarkParagraph = await watermark();
    const dataTableParagraph = await dataTable();
    const dataExtraTableParagraph = await dataExtraTable()
    // Fetch the background image
    const bgResponse = await fetch(backgroundImage);
    const bgBlob = await bgResponse.blob();
    const bgArrayBuffer = await bgBlob.arrayBuffer();

    // Fetch the overlay image
    const overlayResponse = await fetch(overlayImage);
    const overlayBlob = await overlayResponse.blob();
    const overlayArrayBuffer = await overlayBlob.arrayBuffer();

    const pImageResponse1 = await fetch(pillarImage1);
    const pImageBlob1 = await pImageResponse1.blob();
    const pImageBuffer1 = await pImageBlob1.arrayBuffer();

    const pImageResponse2 = await fetch(pillarImage2);
    const pImageBlob2 = await pImageResponse2.blob();
    const pImageBuffer2 = await pImageBlob2.arrayBuffer();

    const pImageResponse3 = await fetch(pillarImage3);
    const pImageBlob3 = await pImageResponse3.blob();
    const pImageBuffer3 = await pImageBlob3.arrayBuffer();

    const pImageResponse4 = await fetch(pillarImage4);
    const pImageBlob4 = await pImageResponse4.blob();
    const pImageBuffer4 = await pImageBlob4.arrayBuffer();

    // Create a header
    const header = new Header({
      children: [
        watermarkParagraph,
        new Paragraph({
          children: [
            new TextRun({
              
              // text: `PILLAR SECURITY – CONFIDENTIAL PROPOSAL FOR ${siteName} (SERVICE – ${s_TotalHoursYear} HOURS) – DATE`,
              text: `PILLAR SECURITY – CONFIDENTIAL PROPOSAL FOR ${siteName.toUpperCase()} (SERVICE – ${totalhours_reg_week} HOURS) – ${getDateTime(date_Created, 'MMM DD, YYYY')}`,
              bold: true,
              font: "Helvetica",
              size: 17, // 12pt
            }),
          ],
          spacing: { after: 200 },
          alignment: "center",
        }),
      ],
    });

    // Create a footer
    const footer = new Footer({
      children: [
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "",
                          font: "Helvetica",
                          size: 20,
                        }),
                      ],
                      alignment: AlignmentType.LEFT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 33.33,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: overlayArrayBuffer,
                          transformation: {
                            width: 100,
                            height: 53,
                          },
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 33.33,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                          font: "Helvetica",
                          size: 20,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 33.33,
                    type: WidthType.PERCENTAGE,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE, // Make the table take the full width of the page
          },
          borders: {
            top: { style: BorderStyle.NONE },
            bottom: { style: BorderStyle.NONE },
            left: { style: BorderStyle.NONE },
            right: { style: BorderStyle.NONE },
            insideHorizontal: { style: BorderStyle.NONE },
            insideVertical: { style: BorderStyle.NONE },
          },
        }),
      ],
    });

    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: { top: 0, right: 0, bottom: 0, left: 0 },
            },
          },
          children: [
            // Insert the full-page background image
            new Paragraph({
              children: [
                new ImageRun({
                  data: bgArrayBuffer,
                  transformation: {
                    width: 1000, // Full page width (in twips, 1 inch = 1440 twips)
                    height: 1250, // Full page height (in twips)
                  },
                }),

                new ImageRun({
                  data: overlayArrayBuffer,
                  transformation: {
                    width: 250,
                    height: 133,
                  },
                  floating: {
                    zIndex: 10,
                    horizontalPosition: {
                      offset: 4.5 * 1014400,
                    },
                    verticalPosition: {
                      offset: 1014400,
                    },
                  },
                }),
              ],
              alignment: "center",
            }),
          ],
        },
        // Page 1
        {
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
            verticalAlign: VerticalAlign.CENTER,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "CONFIDENTIAL PROPOSAL",
                  font: "Helvetica",
                  size: 44,
                }),
              ],
              spacing: { before: 4000, after: 500 },
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "PILLAR SECURITY",
                  font: "Helvetica",
                  size: 44,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
        },
        // Page 1
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "To the attention of the Property Manager and Board of Directors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${siteName} – ${corpNo}`,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${address}`,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${city} ${province} ${postalCode}`,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Dear Property Manager and Board of Directors,",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thank you very much for the opportunity to introduce Pillar Security as a potential concierge and security service provider for the residents of ${siteName}.`,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar cares about the challenges and needs of our clients and will always place their needs first. We win together, never alone. From the moment contact is made, through the proposal process and guard selection, we pledge accountability to our clients with the goal of exceeding their expectations. Our strength is your security. Guard and concierge service are the most effective thing you can do to ensure the security of your residents and the building. As your security partner, Pillar Security can oversee every step in the security process by implementing standard tours tracked by proxy points utilized by the guards, as well as 24/7 monitoring by our own internal monitoring station.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `For twenty-five years, Pillar Security has specialized in concierge and security management of residential condominiums. With a substantial portfolio across the GTA, we bring a wealth of experience to the residents of ${siteName}. We pride ourselves on being a caring and innovative company and have developed robust relationships with every one of our customers. `,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Being security professionals, we understand the importance of motivated and well-trained personnel and as such, the pay rate for Head Concierge is kept at a premium for purposes of retention and in the belief that they are of paramount importance to the improvements in our service. We work closely with every member of our team to ensure consistency in the services we offer. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "During the initial transition period, the Operations Manager will attend to your site three times a week to ensure, evaluate and create tailored standing orders in accordance with the specific needs of the site. Inspection of the security guards will be conducted by the mobile supervisor three times each week, including nights and weekends. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Regular meetings with the Property Manager may be conducted to discuss issues, site improvements and evaluation of the proposed phases to assess the level of achievement during the process of implementation. We, at Pillar Security, understand the scope of this project and are ready to commit considerable resources and time reviewing all pertinent procedures to ensure that security staff, property management, maintenance staff and residents all have a clear understanding of the current procedural guidelines; all to create a safe and pleasant community environment for the residents of ${siteName}.`,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Sincerely,",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Yathusan Rajasekaram",
                  color: "999999",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Senior Operations Manager",
                  color: "999999",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security",
                  color: "999999",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "P: 437.288.4091",
                  color: "999999",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "E: ",
                  font: "Helvetica",
                  size: 20,
                  color: "999999",
                }),
                new TextRun({
                  text: "yrajasekaram@pillarsecurity.com",
                  font: "Helvetica",
                  size: 20,
                  color: "0000FF",
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),
          ],
        },

        // Page 2
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "1.0 Executive Summary",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "1.1 Overview",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "The ability to provide a superior level of service at competitive prices is a constant struggle in any industry.  The challenge becomes even more difficult when you must combine security and emergency response with concierge and hospitality services.  Pillar Security has been successfully navigating these challenges for over twenty-two years.  We are committed to providing a secure and well-managed community environment for all residents.  Some aspects of our service that set us apart include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Software-based booking system facilitating the smooth reservation and inspection of all facilities including those which are shared",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Hands-on management style predicated upon a high level of communication between residents, property management and the Board of Directors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Parking Enforcement, MLEO-Licensed",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Established security company with a high level of expertise in residential condominium security and concierge services",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Highly trained and licensed security staff with a clear understanding of emergency and incident response procedures",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "The Pillar Accreditation Training System (PATS)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Proprietary “Aquarius” communication and security software used as a tool to facilitate the efficient and effective management of all services",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Weekly random site and guard audits providing a metric for continuous improvement",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer1, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 350,
                    height: 222,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4 * 1000000,
                    },
                    verticalPosition: {
                      offset: 1.1 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "1.2 Security and Concierge Services	",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security offers a unique level of service that focuses on superior communication with residents, board members and property management. A seamless integration of the following services is the foundation upon which our operation functions:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Security Services – Security patrols and a sophisticated, software-based system for security logs. A copy of Incident Reports is submitted to Property Management daily.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Safety/Emergency Response – Timely and coordinated responses to fire alarms, suite and mechanical alarms, medical and police situations.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Concierge Services – Coordination of all common facilities such as parking and elevators.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer2, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 250,
                    height: 309,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4.7 * 1000000,
                    },
                    verticalPosition: {
                      offset: 5.2 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 2 * 100000,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "1.3 Management Services",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "We distinguish ourselves from our competition through the ability of our management team to coordinate efforts by fully understanding the daily logistics of each building we service. Our managers and staff take pride in being able to answer your questions and respond to any given situation with an intelligent, informed approach. Our management services include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              // spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Regular visits to each facility by a senior manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "The only security company to offer senior management access 24 hours a day and 7 days a week",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Participation at Board and Committee meetings upon request",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "24-hour off-site monitoring of emergency and mechanical situations by our Dispatch Department",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Comprehensive training of all staff by senior management",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
          ],
        },

        // Page 3
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "1.4 Training Systems and Personnel",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Training of concierge and security personnel is of primary importance to the consistency of the services we offer.  Over the years, we have developed a formal training program named Pillar Accreditation and Training System or PATS. PATS is built upon demonstrated ability within a matrix which provides a metric using random auditing to identify deficiencies and competencies within our staff. Any deficiency is followed with corrective action, while compliance is rewarded with recognition and reward applied using Performance Management Techniques. The training program focuses on, but is not limited to, the following service areas:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire, Emergency and Incident Response",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Premises Patrol",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Software Systems Training",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Regular Audits and Corrective Action",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Customer Satisfaction",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "All security personnel are trained by senior management and are licensed in compliance with the provisions of the Private Investigators and Security Guard Act (“PISGA”).",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "1.5 Communication and Security Software – “Aquarius”",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "The effective communication of accurate information is critical to the security/concierge industry. It is imperative particularly in emergency response situations, to follow designated procedural guidelines. That is why we use Aquarius, a tool that facilitates communication by providing a secure and searchable platform for recording and accessing information. In addition to the capability of accessing this information at critical times, Aquarius virtually eliminates the need for handwritten logs. All information pertaining to parking permits, vehicle ticketing, facility bookings, packages and maintenance inquiries are entered into the system and is easily searchable by authorized individuals. Aquarius promotes an immediate response to any situation by providing faster access to accurate and up to date information. Pillar has a yearly group subscription with Aquarius, and therefore, offers this software free within your contract.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "1.6 Integration Procedures",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "The ability of a company to efficiently integrate its services in a functional manner depends largely on having set criteria and guidelines for carrying out the process.  Prior to commissioning, we will incorporate site-specific elements into the Site Manual template and update procedural guidelines as they develop.  This will be the first step in formulating the implementation of a custom service plan for your facility. Walk-through, meeting with property management, and the Board will be conducted as part of the integration process.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "2.0 Security and Concierge Service",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "2.1 Security Services",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Monitoring security cameras and ensuring video equipment is functional",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Facilitating entry of authorized visitors and trades people",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Enforcing parking rules and regulations",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Responding to telephone inquiries by residents and visitors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Security Patrols - ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Given the limitations of most surveillance equipment, it is imperative that security patrols be routinely executed.  These patrols ensure that no entry points have been compromised while monitoring the operational elements of a building. Security patrols are executed once an hour during the evening and night shifts and usually take approximately 15 minutes.  During the time the guard is away from the desk, our Dispatch Department will remotely monitor the area until the guard returns from patrol. Every element of a building will be examined during a security shift.  Security patrols involve the following procedures:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Inspect all entry points to ensure doors are locked and perimeter is clear",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Check mechanical/electrical rooms for leaks, abnormal pressure readings or unusual odours",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Inspect parking areas (visitors and residents) to ensure parking compliance and check for safety hazards",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrol all stairwells, floor hallways, common areas and facilities to check for signs of forced entry, vandalism, garbage, smoking infractions or areas in disrepair",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Security Logs - ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "The accurate communication of information to relief staff, senior managers and property management is integral to the daily functionality of a building. Traditional handwritten security logs are at times illegible, cumbersome to search and in many cases discourage investigations. Depending on the parameters of an incident, the appropriate individuals are informed automatically via email, with daily, weekly and monthly reports. The checks and balances of the process ensure adherence with established practices and procedures.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "2.2 Safety/Emergency Response",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "A confident and appropriate response to safety and emergency situations can be the difference between a positive or negative outcome.  It is vital for security personnel to have readily available and detailed procedures to follow in an emergency response situation.  This combined with extensive training for all security staff in emergency and incident response sets Pillar Security apart from its competition. Some of the more prevalent incidences and the responses we consider to be appropriate include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire Alarms - ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Keep residents informed throughout the event via P.A. announcements, coordinate monitoring company and Fire Services response, ensure all fire routes and emergency exits are clear of obstacles, reset alarm panels, MAG locks and elevators once situation is reconciled, and inform site supervisor and property manager accordingly.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Suite and Mechanical Alarms – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Investigate and respond accordingly. Inform site supervisor and/or property manager if necessary. Notifying the property management may not always be necessary. For instance, a small leak within a resident suite may only require a phone call to the owner and a recommendation for a plumber. However, should a major heating or air conditioning situation arise, property management must be contacted.  Security’s familiarity of the building and the understanding of the varying degrees of response assist in creating a well-managed environment.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Medical Emergencies – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Coordinate all emergency response procedures.  This would include immediately contacting emergency response services and assisting emergency personnel upon arrival. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "2.3 Customer Service plus Concierge Services",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security approaches the provision of its services from a rather unique perspective.  We feel it is equally important to provide superior concierge services, as it is to maintain a high level of security. This can only be achieved by having an intimate understanding of the resident’s needs. Through a meticulous survey of residents, owners, board members and property management, we will create dedicated post orders for your site. Once the post orders have been created, the appropriate information is inputted in the software and a personal concierge plan is put in place.  Some of the standard services offered include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Module #1 – Customer Service",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Coordinating and administering visitor parking passes",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Facilitating the receipt and delivery of packages",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Coordinating the booking and use of common areas, shared facilities and moving elevators",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Coordinate the access of trades people, real estate agents and visitors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Welcome visitors in a friendly and refined manner",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Anticipate the needs and desires of residents and their visitors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Telephone and in person etiquette",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Module #2 – Concierge Service",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Manage deliveries by ensuring they are completed in a timely fashion",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Perform or assist with any other duties that will facilitate a comfortable and pleasant living experience for the building’s residents",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Routine Concierge Requests",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Assisting the elderly and disabled",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "3.0 Management Services",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "The public impression of the quality of service a company provides is a direct reflection of that company’s management of its workforce and the environment in which it functions.  Pillar Security offers an urbane approach to its services by being well versed with all corporation rules and regulations and when necessary, reminding residents of their responsibilities.  Over time, this will promote enhanced community living by creating a secure and enjoyable environment for all.  This can only be achieved by taking a hands-on management approach whereby a senior supervisor, specifically assigned to oversee your building, attends to your site needs on a regular basis.  To address any emergency situations this supervisor is also available and on-call to property management 24 hours a day, 7 days a week. Hands-on management is also about knowing your building and becoming familiar with all existing mechanical procedures allowing us to maintain existing functional systems while improving procedures which are lacking in direction.  An understanding such as this gives us an advantage during the development of our training procedures whereby specific operational elements of a site can be highlighted.  Security personnel will have the confidence to respond accordingly to most severe situations.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Our management team will work closely with you to customize the security/ hospitality services to precisely fit your needs. We will conduct a detailed questionnaire with the Property Manager and follow up with a full report to the Board of Directors.  Areas of concern will be highlighted, and suggestions will be made to help the Board in its decision process.  Should our presence be requested at Board or Committee meetings to address any of these needs, we will gladly make ourselves available.  A complete post-order manual covering all security and hospitality functions will be created and reviewed on a regular basis ensuring adherence to the process.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "We work closely with our staff, acting as mentors as well as managers and if requested conduct weekly on-site meetings to address any outstanding issues.  We also ensure that the Site Supervisor will effectively communicate any concerns requiring immediate attention.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300, after: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "4.0 Site-Specifics ",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "The ability to address some of the unique functional elements of a building can distinguish one service provider from another. Some elements receiving particular attention include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { after: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Communications – ",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),

                new TextRun({
                  text: "Effective communication between staff and property management is paramount. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Building Access – ",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
                new TextRun({
                  text: "A full audit of your FOBs, access points and facilities. Monitor and record who is permitted access to the buildings to ensure that the safety and security of the residents is not compromised.  Facilitate communication between trade workers, real estate, visitors and residents in common elements to ensure an adequate and seamless transition.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrols – ",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
                new TextRun({
                  text: "Properly staggering and coordinating patrols of a premises helps ensure a secure and pleasant environment for all.  Logging these patrols in a searchable format not only ensures that the patrols are being carried out in a timely manner, but also triggers appropriate responses to situations that may be on-going.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Monitoring – ",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
                new TextRun({
                  text: "Observation of visitors and residents in common elements to ensure adherence with the Corporation’s rules and policies. Pillar Security prides itself on being able to assess the specific needs of a particular building and offer suggestions for creating a safe and harmonious environment to attain the high standard and values.  It is our policy to conduct a full assessment of the existing systems and propose suggestions to the Board to provide a welcoming atmosphere for the residents.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Emergency Response - ",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
                new TextRun({
                  text: "This is by far the most important component of our security training process.  We emphasize all elements related to fire emergency response and personal emergencies.  Our employees are fully trained in fire panel indicators and enunciators, monitoring company procedures, maintenance company responsibilities and sprinkler room valve operations.  We meet with the operations manager of the building to fully understand how these systems function and incorporate them into our training procedures.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "5.0 Communication and Security Software – “Aquarius”",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Manually written reports have been the conventional method used as a means of communication in the security and concierge industry.  Some companies have tried to “pass on” books or use memorandums in an attempt to further enhance communication.  This slow and inefficient model of operation can lead to costly misunderstandings and inaccuracies.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Our proprietary Aquarius software eliminates this confusion and creates an efficient and accessible communication system.  With its powerful search engine, MyCondoLink gives you the ability to search for records on a fully integrated, secure database.  It is designed to facilitate communication between residents, staff, property management and the board.  It monitors vital information and provides detailed procedures to follow in emergency situations.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Some of the features and benefits of the system include:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Searchable security manual for safety and emergency response",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Automated log books with custom formats for ease of entry – no manual notes",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Internal message centre – provides instant messaging and allows for scheduled reminders and bulletins",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Centralized, secure database for all information – authorized individuals can access information off-site via the internet",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Dedicated managing centres – includes modules for visitor parking, package receipt and delivery, incident reporting, facility bookings and suite management",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Internal IT controls governing appropriate levels of access to system (i.e. resident vs. staff vs. management)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "6.0 Training Systems and Personnel",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Consistent and systematic training is critical to providing a timely and appropriate response to any emergency.  The safety and security of all residents and visitors hinges upon having knowledgeable, confident staff in place to respond accordingly. We strive to provide the highest standard of training possible. We believe the people we put in place are ambassadors that represent not only the interests of Pillar Security, but those of the corporation. We are diligent about assessing the strengths and weaknesses of everyone we hire and try to understand their needs. Our industry is based on shift rotation and every shift has its own nature.  It is the responsibility of our management team to understand these needs and incorporate them into the shift structure. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Once this training has been completed, it is the responsibility of our Operations Team and the Dispatch Department to ensure adherence with all safety and security procedures. This is achieved through regular site visits as well as having Dispatch personnel available 24/7 to acknowledge a situation and assist the guards accordingly.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Our training systems have been developed and refined over the years to ensure our staff respond in a diligent, and respectful manner in any given situation. All our training is conducted by a senior supervisor and focuses on the following five key service areas:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire Emergency Response – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "This is by far the most important component of our security training process. We emphasize all elements related to fire emergency response. This includes fire panel indicators, monitoring company procedures, maintenance company responsibilities, and sprinkler room valve operations. We meet with the operations manager of the building to fully understand how these systems function and incorporate them into our training procedures and manuals.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Incident Response – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Specific training is provided on how to appropriately respond to incidents such as theft, flood, domestic violence and medical situations.  Detailed procedures are outlined in the site manual and post orders and are integrated within the Aquarius software system.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Premises Patrol – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Site-specific regulations are developed to ensure all key areas of the premises are systematically patrolled.  Common areas patrols include all exit doors, mechanical rooms, parking garage and exterior grounds.  Specific training is provided on how to assess the severity of observed situations.  Patrols are executed every hour systematically covering all areas of a building.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Concierge Services – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "The most important component of our hospitality service is communication with residents and guests.  It is imperative to consistently communicate in a refined, professional and helpful manner, which in turn creates an enjoyable and functional living environment without compromising the safety and security of the residents.  We feel this component adds value to the property and enhances the quality of life.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Aquarius Software Systems Training – ",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "Complete Aquarius systems training is provided to full-time and part-time personnel with an emphasis on accurate and expedient processing of information.  The Aquarius system is a powerful tool, which, when used properly and efficiently, provides a level of communication between residents and management that is second to none.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "7.0 Integration Procedures",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Integrating services into a facility and instilling confidence into management and residents requires good communication and a clear understanding of expectations.  To accomplish this in a smooth and functional manner, we undertake the following integration procedures: ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Phase 1",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Meet with Property Management and the Board of Directors to gain an understanding of the existing rules and regulations that are governing the corporation.  Become familiar with the base posts orders and incorporate site-specific elements into the procedural guidelines.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Phase 2",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Begin implementation of the Aquarius program and input resident information.  Cross check all administrative elements (i.e. parking spaces, lockers, emergency contacts etc.) to ensure an appropriate flow of information. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Phase 3",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security’s Human Resources Department will then begin to select the security staff for this site. This would include personnel interviews and complete screening of any potential candidates. Personnel already at the site would be given an opportunity to apply for employment with our company. Any new employee applicant that successfully passes the screening process would then begin an extensive training program on the technical and functional elements unique to this facility.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Phase 4",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Throughout the initial start-up process, Pillar Security would address all concerns the Board of Directors and Property Management have regarding the site. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Phase 5",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Conduct a full assessment of the security equipment in place and identify areas of deficiency.  Formulate recommendations for the Board and management to review and provide quotes for new equipment if requested.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "8.0 Employee Wage and Benefits Program",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security’s dedication to excellence in human resources requires that we keep turnover to a minimum. Guards hired from sites taken over from other contractors will be paid in accordance with the provisions of the Employment Standards Act, Section 77. Pillar Security will commit to pay the guards the same or higher wages in a site take over. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Our Security Guard Benefits plan is as outlined below:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Vacation Pay of 4%",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Overtime Pay",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Paid Uniform",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Paid in-class and on-site training",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Benefits provided by Pillar Security:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Employee life $20,000.00",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Employee Accidental Death and Dismemberment $20,000.00",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Dental 80% coverage",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Medical 80% coverage",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Vision care",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Chiropractor",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Physiotherapist Care",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Statutory Payroll Taxes/Benefits:",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Canada Pension Plan Premiums",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Employers Health Tax",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Employment Insurance Premiums",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Workers Compensation Premiums",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
          ],
        },
        // Page 33
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "9.0 Proposed Shift Structure and Billing Rates",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            dataTableParagraph,

            new Paragraph({
              children: [
                new TextRun({
                  text:
                    formatCurrency(unionAmt) +
                    " Additional added to total pre-tax due to Union",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text:
                    formatCurrency(vacationAmt) +
                    " Additional added to total pre-tax due to Vacation",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Events/Extra Coverage",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),
            dataExtraTableParagraph,


          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "10.0 Miscellaneous Items to Note",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              // spacing: { before: 8000 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Quote includes all applicable taxes, insurance, Statutory Holidays and ",
                  font: "Helvetica",
                  size: 20,
                }),
                new TextRun({
                  text: "SunLife Benefit Package.",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Full Value of Aquarius software is included in the quote – fee usually charged at $2 per unit, per month.",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security Mobile Supervisors will conduct regular site visits.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security Operations Managers will conduct regular site visits.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security will conduct a comprehensive security site audit with recommendations within the first month.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Site Manual will be available for review within the first month.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Any additional coverage will be billed at $26.50 per hour plus HST, with a 4-hour minimum.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "With less than 24 hours’ notice, the corporation will be billed at 1.5 times the average regular security guard rate and at 2.5 times the average regular security guard rate during Statutory Holidays.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Where, due to change in government legislation or administrative ruling, some pay rates may be affected, Pillar shall notify the Client in writing of a change to the rates and the effective date of such change 45 days prior to the change taking effect.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "This quote is valid for 60 days from the date of this proposal.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "This pricing is subject to change upon reviewing Bill 7 and Bill 148.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Cell phone for site added at additional cost.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Proposed annual increase of 2.5 – 3.0%",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Tour system includes 25 tour points; additional points can be added at extra cost.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "11.0 Proposal Summary",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security is not simply a company providing a service, but a family of productive, responsible individuals working together to help people live their lives in harmony.  We are proud of our achievements and are confident that we offer a superior level of service and commitment. Some aspects of our service that we feel set us apart from our competition include:  ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Dedicated and knowledgeable management and staff genuinely interested in sustaining a secure and pleasant community environment",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Sophisticated and educated approach to managing the daily security and concierge services of your building with access to our offsite monitoring team, offering 24/7 security",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Hands-on management style",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Established Security Company with a high level of expertise in residential condominium security and concierge services",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Highly trained and licensed security staff with a clear understanding of emergency and incident response procedures",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Ongoing training and testing conducted by our management team",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Proprietary “Aquarius” communication and security software used as a tool to facilitate the efficient and effective management of all services",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 300 },
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer4, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 500,
                    height: 250,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER, // Align image to the right
            }),
          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Pillar Security Scope of Duties",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Emergency Procedures",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire Alarms and announcements – Fire Alarm testing",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Evacuation including those who need assistance",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Emergency exits",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Medical emergencies",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Flood",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Break-and-enter",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Mechanical failure which endangers people",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Major Municipal by-law infractions",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Hazardous spills (gasoline, chlorine, etc.)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Abandoned / lost children / seniors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Assist EMS",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Assault",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Disturbed persons",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Drowning",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Illegal drugs use and trafficking",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Drunkenness",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Disturbances",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fatalities",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Mischief",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Natural Disasters",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Personal injury",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Access Control",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "There are four methods of entry to the building by non-residents, one, Break and Enter, two, Entry authorized by Property Management Office, three, entry authorized by a resident, four, entry by security in which case one or two must be satisfied.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Greet direct, sign in Guest(s), answer pertinent questions",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Monitor access system for unauthorized entry",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Monitor surveillance cameras for unauthorized entry",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Verify status of doors that should be locked",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Receive trades, technicians (cable, etc.) realtors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Assist elderly and handicapped persons",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Visitor Parking",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Open garage door",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Issue parking permit once authorized",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrol Visitor Parking ensuring vehicle have been authorized",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Warning ticketing of unauthorized vehicle(s)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Contact City to issue parking tickets",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Contact towing company for authorized towing",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Ensure Fire Routes are clear",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Enforce corporation rules",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Register vehicles",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Resident Parking",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Ensure residents use only assigned spaces",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Warning ticketing of unauthorized vehicle(s)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Contact city to issue parking tickets",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Contact towing company for authorized towing",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Enforce corporation rules",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Report storage or restricted vehicles in spaces",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Packages",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Receive package(s)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Register package(s)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Sign on behalf of resident if/when authorized",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Notify resident of package arrival",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Have resident sign for package at pick-up",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrols",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrols are 15 minutes in duration",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrols conducted once per hour",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Any anomalies are logged and reported",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Report of any safety hazard(s)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Inspections",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Burnt out light bulbs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire cabinets and equipment",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Hazardous spills (motor oil, gasoline, etc.)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Storage of materials in unauthorized areas",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire pull stations",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Smoke detectors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Heat detectors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Water leaks",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Unauthorized persons",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Unit door locks (visual)",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Emergency exits",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Facilities",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Pre and post use inspections of facilities",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Key Control",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Issue and receipt",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Daily inventory",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Securing keys",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Noise Complaints",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Receiving complaint",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Investigating complaint",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Requesting that noise be reduced",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Follow up with complainant",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Log report of incident",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Lost and Found",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Securing lost items",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Return to owner",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Logging of same",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Logs",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Daily Security Reports by Site Supervisor to Property Management",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Quarterly Reports to Board of Directors by Pillar President and Operations Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Log reports to Aquarius",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Mechanical Logs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Incident Logs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Parking Logs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Patrol Logs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Other Logs",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Public Relations",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Suspicion",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Negative Thinking",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Temper",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Faith",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Prejudice",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Self Esteem",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Flexibility",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Dress and Deportment",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Security and the Law",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Security Guard Authority",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Criminal Offences",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Arrest",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Other Powers of Arrest",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Use of Force",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Break and Enter / Theft Investigation",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Evidence Investigation",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Search of Area",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Search of Suspects",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Protection and Preservation of Evidence",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Condominium Rules and Policies",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Common Elements",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Advertisements",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Bicycles",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Deliveries and Trades people",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Door locks",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Entry phone",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Exercise Room & Saunas",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Garbage Disposal & Recycling",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Going on Vacation",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Moving and Delivers",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Parking for Residents",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Parking for Visitors",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Party Room",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Pets",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Sales of Suites",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Vehicle Repairs and Service/Washing",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Fire Procedures & Regulations",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Swimming Pool",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Extra Guard",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Carry out duties as described",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              bullet: { level: 0 },
            }),
          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "11.0 Live Guard Monitoring ",
                  color: "999999",
                  bold: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Using a combination of hardware, software/analytics and human intelligence, our sister company Pillar Technologies deploys Live Video Monitoring solutions, customized to help secure various residential properties across the GTA. ",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "We deploy this technology to monitor our staff by installing cameras on site covering the concierge desk and other accessible areas that focuses on our guard. This service is used for performance management and more importantly health and safety. Then monitor, and grant access to visitors, contractors or patrol services on off hours where the virtual concierge service is used.",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300, after: 300 },
              alignment: AlignmentType.JUSTIFIED,
            }),

            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer3, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 550,
                    height: 307,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER, // Align image to the right
            }),
          ],
        },

        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: {
                top: 1000,
                right: 1000,
                bottom: 400,
                header: 300,
                footer: 180,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "REFERENCES",
                  bold: true,
                  underline: true,
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "ICON Property Management",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Infinity 4 – 25 Lower Simcoe, Toronto",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Roland Kani",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(416) 236-7979",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "St. George Property Management",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Lotus - 8 Scollard Street",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Bruno Hertl",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(416) 922-1942",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            //----------
            new Paragraph({
              children: [
                new TextRun({
                  text: "ICON Property Management",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Queen’s Harbour – 600 Queen’s Quay West",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Nadir El Achhab",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(416) 596-1641",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            ///--------------
            new Paragraph({
              children: [
                new TextRun({
                  text: "Crossbridge Condominium Services, Ltd.",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Minto – 30 Roehampton Avenue",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Lhot Clarke",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(647) 348-1164",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            ///---
            new Paragraph({
              children: [
                new TextRun({
                  text: "Coldwell Management",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Rosedale Village  25-55 Via Rosedale",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Palmina Maccari",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(905) 455-3344",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            ///---
            new Paragraph({
              children: [
                new TextRun({
                  text: "ICON Property Management",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              spacing: { before: 300 },
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Bohemian Embassy Lofts – 1169-1171 Queen Street West",
                  font: "Helvetica",
                  bold: true,
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),

            new Paragraph({
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
                },
              ],
              children: [
                new TextRun({
                  text: "Barry Hada",
                  font: "Helvetica",
                  size: 20,
                }),

                new TextRun({
                  text: "\t",
                  size: 20,
                }),

                new TextRun({
                  text: "(416) 792-3222",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Property Manager",
                  font: "Helvetica",
                  size: 20,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, "Proposal.docx");
  };

  return (
    <div>
      <button
                type="button"
                onClick={generateDocument}
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mx-auto block"
              >
                Download Proposal
              </button>
    </div>
  );
};

export default ExportData2;
