import React from "react";
// import { PaymentProvider } from "../context/PaymentContext";
import PaymentCard from "./PaymentCard";
import ProposalCards from "./ProposalCards";
const ProposalPlan = () => {
  return (
    // <PaymentProvider>
      <div className="min-h-screen flex flex-col justify-center items-center space-y-4">
        <div className="w-full">
          <PaymentCard />
        </div>
        <div className="w-full">
          <ProposalCards />
        </div>
      </div>
    // </PaymentProvider>
  );
};
export default ProposalPlan;
