import { call, put, select } from "redux-saga/effects";
import axios from "axios";
import config from "../../config";
import * as adminActions from "../actions/adminActions";
import * as proposalActions from "../actions/proposalActions";

// Helper function to make API requests and handle token expiration
export function* callApiWithToken(apiCall, payload) {
  let token = yield select((state) => state.admin.data.accessToken);
//   console.log("callApiWithToken");
//   console.log(token);
//   console.log(payload);

  try {
    // Attempt the API call with the current token
    return yield call(apiCall, token, payload);
  } catch (error) {
    if (error.response && error.response.status === 401) {
    //   console.log("Token Expired");
      //   const refreshToken = yield select(
      //     (state) => state.admin.data.refreshToken
      //   );
      //   const empid = yield select((state) => state.admin.data.empid);

      const refreshToken = localStorage.getItem("refresh_token");
      const empid = localStorage.getItem("empid");
      if (refreshToken) {
        try {
          const refobj = {
            refreshtoken: refreshToken,
            empid: parseInt(empid),
          };
          const refreshResponse = yield call(refreshTokenApi, refobj);
          const data = refreshResponse.data;
          if (data.errorlogin === "0") {
            localStorage.setItem("access_token", data.accessToken);
            localStorage.setItem("refresh_token", data.refreshToken);

            // Update the token in the state and localStorage
            yield put(adminActions.refreshTokenSuccess(data.accessToken));
            // Retry the original API call with the new token
            return yield call(apiCall, data.accessToken, payload);
          }
        } catch (refreshError) {
          // Refresh token failed, redirect to login
          yield put(
            proposalActions.fetchProposalFailure(
              "Session expired, please log in again."
            )
          );
          window.location.href = "/";
          return;
        }
      } else {
        // No refresh token available, redirect to login
        yield put(
          proposalActions.fetchProposalFailure(
            "Session expired, please log in again."
          )
        );
        window.location.href = "/";
        return;
      }
    } else {
      // Some other error occurred
      throw error;
    }
  }
}

// Mock refresh token API call (replace with your real refresh token API)
function refreshTokenApi(refobj) {
  return axios.post(config.APIURL.emploginapi.refresh, refobj);
}
