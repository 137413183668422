const BASEURL = process.env.REACT_APP_BASEURL;
const IMAGEURL = process.env.REACT_APP_IMAGEURL;
const DOCURL = process.env.REACT_APP_DOCURL;


const APIURL = {
  emploginapi: {
    login: BASEURL + "/emploginapi/login",
    refresh: BASEURL + '/emploginapi/refresh',
  },
  proposalapi: {
    display: BASEURL + "/proposalapi/display",
    displaybyid: BASEURL + "/proposalapi/displaybyid",
    create: BASEURL + "/proposalapi/create",
  },
};

// export default { BASEURL, IMAGEURL, APIURL };

const config = { BASEURL, IMAGEURL, DOCURL, APIURL };
export default config;
