import React, { useEffect, useState } from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProposalCards from "./ProposalCards";
import { fetchProposalByIdRequest } from "../redux/actions/proposalActions";

const DisplayProposal = () => {
  const { paymentData, updatePaymentData } = usePaymentContext();
  const dispatch = useDispatch();
  const { id } = useParams();
  const databyid = useSelector((state) => state.proposal.databyid);
  const loading = useSelector((state) => state.proposal.loading);
  const error = useSelector((state) => state.proposal.error);

  const [dataUpdated, setDataUpdated] = useState(false);
  const [data, setData] = useState([]);

  const {
    corpNo,
    siteName,
    address,
    city,
    province,
    postalCode,
    phone,
    email,
    supervisorTitle,
    supervisorProfit,
    supervisorHours,
    supervisorPayrate,
    supervisorStatHours,
    conciergeTitle,
    conciergeProfit,
    conciergeHours,
    conciergePayrate,
    conciergeStatHours,
    patrolTitle,
    patrolProfit,
    patrolHours,
    patrolPayrate,
    patrolStatHours,
    extraTitle1,
    extraProfit1,
    extraHours1,
    extraPayrate1,
    extraStatHours1,
    extraTitle2,
    extraProfit2,
    extraHours2,
    extraPayrate2,
    extraStatHours2,
    unionAmt,
    vacationAmt,
    additionalAmt,
    year2Percent,
    year3Percent,
    year4Percent,
    year5Percent,
    overheadAmt,
    cppPercent,
    eiPercent,
    wsibPercent,
    healthTax,
  } = paymentData;

  useEffect(() => {
    // Clear the data before fetching new one
  dispatch({ type: 'CLEAR_PROPOSAL_DATA' });
    const propobj = {
      id: parseInt(id),
    };
    dispatch(fetchProposalByIdRequest(propobj));
    setDataUpdated(false);
  }, [dispatch, id]);

  useEffect(() => {
    if(!loading && databyid.length>0)
    {
    // console.log(databyid);
    setDataUpdated(true);
    setData(databyid[0])
    }
  }, [databyid, loading]);

  useEffect(() => {
    if(dataUpdated)
    {
      const item = data;
      updatePaymentData({
        id: data.id,
        corpNo: item.corpno || "",
        siteName: item.sitename || "",
        address: item.address || "",
        city: item.city || "",
        province: item.province || "",
        postalCode: item.postalCode || "",
        phone: item.phone || "",
        email: item.email || "",
        date_Created: item.date_Created,

        supervisorTitle: item.supervisorTitle || "Site Supervisor",
        supervisorProfit: item.supervisorProfit || "38",
        supervisorPayrate: item.supervisorPayrate || "10",
        supervisorHours: item.supervisorHours || "40",
        supervisorStatHours: item.supervisorStatHours || "8",

        conciergeTitle: item.conciergeTitle || "Concierge",
        conciergeProfit: item.conciergeProfit || "38",
        conciergePayrate: item.conciergePayrate || "5",
        conciergeHours: item.conciergeHours || "128",
        conciergeStatHours: item.conciergeStatHours || "16",

        patrolTitle: item.patrolTitle || "Patrol",
        patrolProfit: item.patrolProfit || "",
        patrolPayrate: item.patrolPayrate || "",
        patrolHours: item.patrolHours || "",
        patrolStatHours: item.patrolStatHours || "",

        extraTitle1: item.extraTitle1 || "",
        extraProfit1: item.extraProfit1 || "",
        extraPayrate1: item.extraPayrate1 || "",
        extraHours1: item.extraHours1 || "",
        extraStatHours1: item.extraStatHours1 || "",

        extraTitle2: item.extraTitle2 || "",
        extraProfit2: item.extraProfit2 || "",
        extraPayrate2: item.extraPayrate2 || "",
        extraHours2: item.extraHours2 || "",
        extraStatHours2: item.extraStatHours2 || "",

        unionAmt: item.unionAmt || "",
        vacationAmt: item.vacationAmt || "",
        additionalAmt: item.additionalAmt || "",

        year2Percent: item.year2Percent || "2.5",
        year3Percent: item.year3Percent || "2.5",
        year4Percent: item.year4Percent || "",
        year5Percent: item.year5Percent || "",

        overheadAmt: item.overheadAmt || "",
        cppPercent: item.cppPercent || "",
        eiPercent: item.eiPercent || "",
        wsibPercent: item.wsibPercent || "",
        healthTax: item.healthTax || "",

        hst: item.hst || 13,
      });
      setDataUpdated(false);
    }
  }, [data, dataUpdated, updatePaymentData]); 

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="min-h-screen flex flex-col justify-center databyid-center space-y-4">
      <div className="flex flex-wrap">
        <div className="w-full p-0.5 bg-purple-200">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-0.5 mb-0.5">
            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label htmlFor="corpNo" className="text-sm font-semibold mb-2">
                  Corporation No
                </label>
                {corpNo}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label
                  htmlFor="siteName"
                  className="text-sm font-semibold mb-2"
                >
                  Site Name
                </label>
                {siteName}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label htmlFor="address" className="text-sm font-semibold mb-2">
                  Address
                </label>
                {address}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label htmlFor="city" className="text-sm font-semibold mb-2">
                  City
                </label>
                {city}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-0.5 mb-0.5">
            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label
                  htmlFor="province"
                  className="text-sm font-semibold mb-2"
                >
                  Province
                </label>
                {province}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label
                  htmlFor="postalCode"
                  className="text-sm font-semibold mb-2"
                >
                  Postal Code
                </label>
                {postalCode}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label htmlFor="phone" className="text-sm font-semibold mb-2">
                  Phone
                </label>
                {phone}
              </div>
            </div>

            <div className="bg-purple-100 p-2">
              <div className="flex flex-col">
                <label htmlFor="email" className="text-sm font-semibold mb-2">
                  Email
                </label>
                {email}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-7/10 p-0.5 bg-blue-200">
          <div className="hidden sm:grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
            <div className="bg-sky-100 p-2 text-lg font-semibold"></div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="profitPer"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                Profit (%)
              </label>
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="hoursPerWeek"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                No of Hours per Week
              </label>
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="payRate"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                Pay Rate ($/hour)
              </label>
            </div>

            <div className="bg-sky-100 p-2">
              <label
                htmlFor="statHours"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                Statutory Holidays
              </label>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
            <div className="bg-sky-100 p-3 text-sm font-semibold">
              {supervisorTitle}
            </div>
            <div className="bg-sky-100 p-3">{supervisorProfit}%</div>
            <div className="bg-sky-100 p-3">{supervisorHours}</div>
            <div className="bg-sky-100 p-3">${supervisorPayrate}</div>

            <div className="bg-sky-100 p-3">{supervisorStatHours}</div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
            <div className="bg-sky-100 p-3 text-sm font-semibold">
              {conciergeTitle}
            </div>
            <div className="bg-sky-100 p-3">{conciergeProfit}%</div>
            <div className="bg-sky-100 p-3">{conciergeHours}</div>
            <div className="bg-sky-100 p-3">${conciergePayrate}</div>

            <div className="bg-sky-100 p-3">{conciergeStatHours}</div>
          </div>
          {patrolProfit && (
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-3 text-sm font-semibold">
                {patrolTitle}
              </div>
              <div className="bg-sky-100 p-3">{patrolProfit}%</div>
              <div className="bg-sky-100 p-3">{patrolHours}</div>
              <div className="bg-sky-100 p-3">${patrolPayrate}</div>

              <div className="bg-sky-100 p-3">{patrolStatHours}</div>
            </div>
          )}
          {extraProfit1 && (
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-3 text-sm font-semibold">
                {extraTitle1}
              </div>
              <div className="bg-sky-100 p-3">{extraProfit1}%</div>
              <div className="bg-sky-100 p-3">{extraHours1}</div>
              <div className="bg-sky-100 p-3">${extraPayrate1}</div>

              <div className="bg-sky-100 p-3">{extraStatHours1}</div>
            </div>
          )}
          {extraProfit2 && (
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-3 text-sm font-semibold">
                {extraTitle2}
              </div>
              <div className="bg-sky-100 p-3">{extraProfit2}%</div>
              <div className="bg-sky-100 p-3">{extraHours2}</div>
              <div className="bg-sky-100 p-3">${extraPayrate2}</div>

              <div className="bg-sky-100 p-3">{extraStatHours2}</div>
            </div>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
            <div className="bg-sky-100 p-2 text-sm font-semibold">
              Add $$$ to Charge Client
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="unionAmt"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Union
              </label>
              ${unionAmt}
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="vacationAmt"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Vacation
              </label>
              ${vacationAmt}
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="additionalAmt"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Additional
              </label>
              ${additionalAmt}
            </div>

            <div className="bg-sky-100 p-2"></div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
            <div className="bg-sky-100 p-2 text-sm font-semibold">
              Yearly Increase (%)
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="year2Percent"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Year 2
              </label>
              {year2Percent}%
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="year3Percent"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Year 3
              </label>
              {year3Percent}%
            </div>
            <div className="bg-sky-100 p-2">
              <label
                htmlFor="year4Percent"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Year 4
              </label>
              {year4Percent}%
            </div>

            <div className="bg-sky-100 p-2">
              <label
                htmlFor="year5Percent"
                className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
              >
                Year 5
              </label>
              {year5Percent}%
            </div>
          </div>
        </div>

        <div className="w-full md:w-3/10 p-1 bg-indigo-200">
          <div className="space-y-2">
            <div className="bg-indigo-100 p-3">
              <div className="grid grid-cols-3 gap-2 items-center">
                <label
                  htmlFor="overheadAmt"
                  className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                >
                  Overhead
                </label>
                ${overheadAmt}
              </div>
            </div>

            <div className="bg-indigo-100 p-3">
              <div className="grid grid-cols-3 gap-2 items-center">
                <label
                  htmlFor="cppPercent"
                  className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                >
                  Employer CPP
                </label>
                {cppPercent}%
              </div>
            </div>

            <div className="bg-indigo-100 p-3">
              <div className="grid grid-cols-3 gap-2 items-center">
                <label
                  htmlFor="eiPercent"
                  className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                >
                  Employer EI
                </label>
                {eiPercent}%
              </div>
            </div>

            <div className="bg-indigo-100 p-3">
              <div className="grid grid-cols-3 gap-2 items-center">
                <label
                  htmlFor="wsibPercent"
                  className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                >
                  WSIB
                </label>
                {wsibPercent}%
              </div>
            </div>

            <div className="bg-indigo-100 p-3">
              <div className="grid grid-cols-3 gap-2 items-center">
                <label
                  htmlFor="healthTax"
                  className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                >
                  Health Tax
                </label>
                {healthTax}%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <ProposalCards />
      </div>
    </div>
  );
};

export default DisplayProposal;
